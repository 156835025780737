@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    max-width: 2000px!important;
}

html{
    font-size: 62.5%;
}

.page--container{
    margin-top: calc(4rem + 7.5dvw); /* So the pages container starts bellow the header  */
}

@media screen and (max-width: 600px) {
    .page--container{
        margin-top: calc(4rem + 15dvw); /* So the pages container starts bellow the header  */
    }
}

:root {
    --main-color: #3066BE;
    --main-color-dark: #113773;
    --white-color: #F8F7FF;
    --black-color: #01161E;
    --success-color: #60D394;
    --error-color: #AF3E4D;
    --main-font: "PT Sans", sans-serif;
    --main-font-regular-weight: 400;
    --main-font-bold-weight: 700;
}

body {
    background-color: var(--white-color);
}

@media screen and (min-width: 2000px) {
    body{
        width: 2000px; /* If the screen is larger than 2000px (which is rare) we just stop the body from being bigger, so there will be worders on the side */
    }
}


/* general class */

.d-none{
    display: none!important;
}